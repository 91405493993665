
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { PartialSite } from '@/interfaces/Site';

import {
  getSite,
  IntBoolean
} from '@/services/api';

export default defineComponent({
  setup() {
    const site = ref<PartialSite>({});
    const siteId = useRoute().params.id as string;
    const page = useRoute().query.page as string;

    const fetchSites = async() => {
      const { data } = await getSite({ siteId });
      site.value = data;
    };

    onMounted(() => {
      fetchSites();
    });

    return {
      site,
      page,
      IntBoolean
    };
  }
});
